import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

class App extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      isSuccess: false
    }
  }

  componentDidMount(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email')
    const otp = urlParams.get('otp')

    const apiUrl = 'https://api.demngayyeu.vn/demngayyeu/verifyotpactive';

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    const data = {
      email: email,
      otp: otp,
    };

    axios.post(apiUrl, data, { headers })
      .then(response => {
        console.log('Response:', response.data);
        this.setState({isSuccess: true})
        // Handle successful response here
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error here
      });

    

    // console.log("email",email,otp)

  }
  render() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const link = urlParams.get('link')
    return (
      <div className="App">
        <h1>Verify email </h1>
        {
          this.state.isSuccess ? <h3>Verify email successful</h3>:<div></div>
        }
      </div>
    );
  }
}

export default App;
